import React, { useEffect, useState } from "react";
import { Navbar } from "../component/navbar/Navbar";
import { useNavigate, useParams } from "react-router-dom";
import {
  calculateEggFreezingResults,
  calculateFutureResults,
} from "../utils/egg_freezing_calc";
// import {
//   calculateEggFreezingResults,
//   calculateFutureResults,
// } from "./calculator";

export const Results = ({
  pagevalues,
  setpagevalues,
  formData,
  Year,
  setYear,
}) => {
  const { type = "" } = useParams();
  const nav = useNavigate();
  // const [pagevalues, setpagevalues] = useState({
  //   age: 28,
  //   cycle: 1,
  //   eggs: 7,
  //   "1_live_birth": 51,
  //   "2_live_birth": 24,
  //   prediction: [
  //     {
  //       year: "Today",
  //       eggs: 14,
  //       "1_live_birth": 51,
  //       "2_live_birth": 24,
  //     },
  //     {
  //       year: "+1 year",
  //       eggs: 10,
  //       "1_live_birth": 48,
  //       "2_live_birth": 20,
  //     },
  //     {
  //       year: "+3 year",
  //       eggs: 7,
  //       "1_live_birth": 40,
  //       "2_live_birth": 15,
  //     },
  //     {
  //       year: "+5 year",
  //       eggs: 4,
  //       "1_live_birth": 30,
  //       "2_live_birth": 10,
  //     },
  //   ],
  // });

  let data1 = Year.data[0];
  let data2 = Year.data[1];
  let data3 = Year.data[2];
  useEffect(() => {
    if (!formData.age) {
      nav("/egg-freezing-calculator");
    }
  }, [formData]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scrolling to the top
    });
  }, []);

  return (
    <>
      <Navbar
        {...{
          nobackbtn: !type,
          goback: () => {
            nav(-1);
          },
        }}
      />
      <div className="results_page">
        <div className="results_page_inner">
          <div className="results_page_title">
            At age <span>{formData.age}</span>, you’d get an estimated number
            of...
          </div>
          <div className="results_page_box">
            <div className="results_page_title">
              {data1?.cycles} cycle of Egg Freezing
            </div>
            <div className="results_page_egg">
              <div>{data1?.total_oocytes}</div>
              <span>Eggs</span>
            </div>
            <div className="results_page_live">
              {data1?.live_birth_chance_1}% chance for 1 live birth
            </div>
            <div className="results_page_live">
              {data1?.live_birth_chance_2}% chance for 2 live birth
            </div>
          </div>
          <div className="results_page_waiting">Considering waiting?</div>
          <div className="results_page_test_box">
            <div className="test_box_bg"></div>
            <div className="test_box_bundle">Bundle</div>
            <div className="test_box_title">Egg Freezing Evalation</div>
            <div className="test_box_desc">
              First step in getting crucial insights and guidance on deciding if
              egg freezing is right for you.
            </div>
            <div
              className="test_box_btn"
              onClick={() => {
                window.open(
                  "https://shop.arva.health/cart?ProductId=AB02",
                  "_blank"
                );
              }}
            >
              Book Now
            </div>
          </div>
          <div className="results_page_comm_title">
            Check how your results could change in the coming years.
          </div>
          <div className="results_page_comm_box_header">
            {pagevalues.map((el, index) => (
              <div
                key={index}
                className="comm_box_year"
                active={(el.year === Year.year).toString()}
                onClick={() => {
                  setYear(el);
                }}
              >
                {el.year}
              </div>
            ))}
          </div>
          {Year?.year
            ? [data2, data3].map((data, i) => (
                <div className="results_page_box">
                  <div className="results_page_spaceing"></div>
                  <div className="results_page_egg">
                    <div>{data?.total_oocytes}</div>
                    <span>Eggs</span>
                  </div>
                  <div className="results_page_live">
                    {data?.live_birth_chance_1}% chance for 1 live birth
                  </div>
                  <div className="results_page_live">
                    {data?.live_birth_chance_2}% chance for 2 live birth
                  </div>
                </div>
              ))
            : ""}
        </div>
      </div>
    </>
  );
};
