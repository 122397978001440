import React from "react";
import { Outlet, useRoutes } from "react-router-dom";
import { CalculatorPages } from "./pages/CalculatorPages";
import { ErrorPage } from "./pages/ErrorPage";

export const Layout = () => {
  const routes = [
    {
      path: "egg-freezing-calculator",
      element: <Outlet />,
      children: [
        {
          path: ":type?",
          element: <CalculatorPages />,
        },
      ],
    },

    {
      path: "/*",
      element: <ErrorPage />,
    },
  ];
  return <div className="layout">{useRoutes(routes)}</div>;
};
