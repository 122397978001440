import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Cover } from "./Cover";
import { Form } from "./Form";
import { Results } from "./Results";
import {
  calculateEggFreezingResults,
  calculateFutureResults,
} from "../utils/egg_freezing_calc";

export const CalculatorPages = () => {
  const { type = "" } = useParams();
  const [resultsvalues, setresultsvalues] = useState([
    {
      year: "Today",
      data: [],
      id: "today",
    },
    {
      year: "+1 year",
      data: [],
      id: 1,
    },
    {
      year: "+3 year",
      data: [],
      id: 3,
    },
    {
      year: "+5 year",
      data: [],
      id: 5,
    },
  ]);
  const [pagevalues, setpagevalues] = useState({
    age: "",
    height: {
      ft: "",
      in: "",
    },
    weight: "",
    ethnicity: "Asian",
    amh_ans: "",
    amhvalue: "",
    dignosed: [],
    name: "",
    phone: "",
    phone_code: {
      flag_code: "in",
      code: "+91",
      name: "India",
    },
    // age: "25",
    // height: {
    //   ft: "5",
    //   in: "0",
    // },
    // weight: "100",
    // ethnicity: "Asian",
    // amh_ans: "Yes",
    // amhvalue: "1",
    // dignosed: ["PCOS"],
    // name: "XYZ",
    // phone: "44444 44444",
    // phone_code: {
    //   flag_code: "in",
    //   code: "+91",
    //   name: "India",
    // },
  });
  const [Year, setYear] = useState({
    year: "Today",
    data: [],
    id: "today",
  });
  const [dignosedList, setDignosedList] = useState([
    {
      name: "PCOS",
      i_desc:
        "PCOS (Polycystic Ovary Syndrome) is a hormonal disorder that affects women, causing irregular periods, excess male hormones, and ovarian cysts, often leading to fertility issues and insulin resistance",
      checkbox: pagevalues?.dignosed?.includes("PCOS"),
    },
    {
      name: "Endometriosis",
      i_desc:
        "Endometriosis is a condition where tissue similar to the lining of the uterus grows outside the uterus, causing pain, heavy periods, and sometimes fertility issues. It commonly affects the ovaries, fallopian tubes, and surrounding pelvic tissues.",
      checkbox: pagevalues?.dignosed?.includes("Endometriosis"),
    },
  ]);
  useEffect(() => {
    setresultsvalues((prev) =>
      prev.map((el) => ({
        ...el,
        data:
          el.id == "today"
            ? calculateEggFreezingResults(
                parseInt(pagevalues.age),
                parseInt(pagevalues.height.ft),
                parseInt(pagevalues.height.in || 0),
                parseFloat(pagevalues.weight),
                pagevalues.ethnicity.toLowerCase(),
                pagevalues.amh_ans === "yes",
                parseFloat(pagevalues.amhvalue),
                pagevalues.dignosed.includes("PCOS"),
                pagevalues.dignosed.includes("Endometriosis")
              )
            : calculateFutureResults(
                parseInt(pagevalues.age),
                el.id,
                parseFloat(pagevalues.amhvalue),
                parseInt(pagevalues.height.ft),
                parseInt(pagevalues.height.in || 0),
                parseFloat(pagevalues.weight),
                pagevalues.ethnicity.toLowerCase(),
                pagevalues.amh_ans === "yes",
                pagevalues.dignosed.includes("PCOS"),
                pagevalues.dignosed.includes("Endometriosis")
              ),
      }))
    );
  }, [pagevalues]);

  useEffect(() => {
    setYear({
      ...resultsvalues[0],
    });
  }, [resultsvalues]);

  return (
    <>
      {
        {
          "": <Cover />,
          form: (
            <Form
              {...{
                resultsvalues,
                setresultsvalues,
                pagevalues,
                setpagevalues,
                dignosedList,
                setDignosedList,
              }}
            />
          ),
          results: (
            <Results
              {...{
                pagevalues: resultsvalues,
                setpagevalues: setresultsvalues,
                formData: pagevalues,
                Year,
                setYear,
              }}
            />
          ),
        }[type]
      }
    </>
  );
};
