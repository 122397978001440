export const flags = [
  {
    flag_code: "in",
    code: "+91",
    name: "India",
  },
  {
    flag_code: "us",
    code: "+1",
    name: "United States",
  },
  {
    flag_code: "af",
    code: "+93",
    name: "Afghanistan",
  },
  {
    flag_code: "al",
    code: "+355",
    name: "Albania",
  },
  {
    flag_code: "dz",
    code: "+213",
    name: "Algeria",
  },
  {
    flag_code: "ad",
    code: "+376",
    name: "Andorra",
  },
  {
    flag_code: "ao",
    code: "+244",
    name: "Angola",
  },
  {
    flag_code: "ag",
    code: "+1268",
    name: "Antigua and Barbuda",
  },
  {
    flag_code: "ar",
    code: "+54",
    name: "Argentina",
  },
  {
    flag_code: "am",
    code: "+374",
    name: "Armenia",
  },
  {
    flag_code: "aw",
    code: "+297",
    name: "Aruba",
  },
  {
    flag_code: "au",
    code: "+61",
    name: "Australia",
  },
  {
    flag_code: "at",
    code: "+43",
    name: "Austria",
  },
  {
    flag_code: "az",
    code: "+994",
    name: "Azerbaijan",
  },
  {
    flag_code: "bs",
    code: "+1242",
    name: "Bahamas",
  },
  {
    flag_code: "bh",
    code: "+973",
    name: "Bahrain",
  },
  {
    flag_code: "bd",
    code: "+880",
    name: "Bangladesh",
  },
  {
    flag_code: "bb",
    code: "+1246",
    name: "Barbados",
  },
  {
    flag_code: "by",
    code: "+375",
    name: "Belarus",
  },
  {
    flag_code: "be",
    code: "+32",
    name: "Belgium",
  },
  {
    flag_code: "bz",
    code: "+501",
    name: "Belize",
  },
  {
    flag_code: "bj",
    code: "+229",
    name: "Benin",
  },
  {
    flag_code: "bt",
    code: "+975",
    name: "Bhutan",
  },
  {
    flag_code: "bo",
    code: "+591",
    name: "Bolivia",
  },
  {
    flag_code: "ba",
    code: "+387",
    name: "Bosnia and Herzegovina",
  },
  {
    flag_code: "bw",
    code: "+267",
    name: "Botswana",
  },
  {
    flag_code: "br",
    code: "+55",
    name: "Brazil",
  },
  {
    flag_code: "io",
    code: "+246",
    name: "British Indian Ocean Territory",
  },
  {
    flag_code: "bn",
    code: "+673",
    name: "Brunei",
  },
  {
    flag_code: "bg",
    code: "+359",
    name: "Bulgaria",
  },
  {
    flag_code: "bf",
    code: "+226",
    name: "Burkina Faso",
  },
  {
    flag_code: "bi",
    code: "+257",
    name: "Burundi",
  },
  {
    flag_code: "kh",
    code: "+855",
    name: "Cambodia",
  },
  {
    flag_code: "cm",
    code: "+237",
    name: "Cameroon",
  },
  {
    flag_code: "ca",
    code: "+1",
    name: "Canada",
  },
  {
    flag_code: "cv",
    code: "+238",
    name: "Cape Verde",
  },
  {
    flag_code: "bq",
    code: "+599",
    name: "Caribbean Netherlands",
  },
  {
    flag_code: "cf",
    code: "+236",
    name: "Central African Republic",
  },
  {
    flag_code: "td",
    code: "+235",
    name: "Chad",
  },
  {
    flag_code: "cl",
    code: "+56",
    name: "Chile",
  },
  {
    flag_code: "cn",
    code: "+86",
    name: "China",
  },
  {
    flag_code: "co",
    code: "+57",
    name: "Colombia",
  },
  {
    flag_code: "km",
    code: "+269",
    name: "Comoros",
  },
  {
    flag_code: "cd",
    code: "+243",
    name: "Congo",
  },
  {
    flag_code: "cg",
    code: "+242",
    name: "Congo",
  },
  {
    flag_code: "cr",
    code: "+506",
    name: "Costa Rica",
  },
  {
    flag_code: "ci",
    code: "+225",
    name: "Côte d’Ivoire",
  },
  {
    flag_code: "hr",
    code: "+385",
    name: "Croatia",
  },
  {
    flag_code: "cu",
    code: "+53",
    name: "Cuba",
  },
  {
    flag_code: "cw",
    code: "+599",
    name: "Curaçao",
  },
  {
    flag_code: "cy",
    code: "+357",
    name: "Cyprus",
  },
  {
    flag_code: "cz",
    code: "+420",
    name: "Czech Republic",
  },
  {
    flag_code: "dk",
    code: "+45",
    name: "Denmark",
  },
  {
    flag_code: "dj",
    code: "+253",
    name: "Djibouti",
  },
  {
    flag_code: "dm",
    code: "+1767",
    name: "Dominica",
  },
  {
    flag_code: "do",
    code: "+1",
    name: "Dominican Republic",
  },
  {
    flag_code: "ec",
    code: "+593",
    name: "Ecuador",
  },
  {
    flag_code: "eg",
    code: "+20",
    name: "Egypt",
  },
  {
    flag_code: "sv",
    code: "+503",
    name: "El Salvador",
  },
  {
    flag_code: "gq",
    code: "+240",
    name: "Equatorial Guinea",
  },
  {
    flag_code: "er",
    code: "+291",
    name: "Eritrea",
  },
  {
    flag_code: "ee",
    code: "+372",
    name: "Estonia",
  },
  {
    flag_code: "et",
    code: "+251",
    name: "Ethiopia",
  },
  {
    flag_code: "fj",
    code: "+679",
    name: "Fiji",
  },
  {
    flag_code: "fi",
    code: "+358",
    name: "Finland",
  },
  {
    flag_code: "fr",
    code: "+33",
    name: "France",
  },
  {
    flag_code: "gf",
    code: "+594",
    name: "French Guiana",
  },
  {
    flag_code: "pf",
    code: "+689",
    name: "French Polynesia",
  },
  {
    flag_code: "ga",
    code: "+241",
    name: "Gabon",
  },
  {
    flag_code: "gm",
    code: "+220",
    name: "Gambia",
  },
  {
    flag_code: "ge",
    code: "+995",
    name: "Georgia",
  },
  {
    flag_code: "de",
    code: "+49",
    name: "Germany",
  },
  {
    flag_code: "gh",
    code: "+233",
    name: "Ghana",
  },
  {
    flag_code: "gr",
    code: "+30",
    name: "Greece",
  },
  {
    flag_code: "gd",
    code: "+1473",
    name: "Grenada",
  },
  {
    flag_code: "gp",
    code: "+590",
    name: "Guadeloupe",
  },
  {
    flag_code: "gu",
    code: "+1671",
    name: "Guam",
  },
  {
    flag_code: "gt",
    code: "+502",
    name: "Guatemala",
  },
  {
    flag_code: "gn",
    code: "+224",
    name: "Guinea",
  },
  {
    flag_code: "gw",
    code: "+245",
    name: "Guinea-Bissau",
  },
  {
    flag_code: "gy",
    code: "+592",
    name: "Guyana",
  },
  {
    flag_code: "ht",
    code: "+509",
    name: "Haiti",
  },
  {
    flag_code: "hn",
    code: "+504",
    name: "Honduras",
  },
  {
    flag_code: "hk",
    code: "+852",
    name: "Hong Kong",
  },
  {
    flag_code: "hu",
    code: "+36",
    name: "Hungary",
  },
  {
    flag_code: "is",
    code: "+354",
    name: "Iceland",
  },

  {
    flag_code: "id",
    code: "+62",
    name: "Indonesia",
  },
  {
    flag_code: "ir",
    code: "+98",
    name: "Iran",
  },
  {
    flag_code: "iq",
    code: "+964",
    name: "Iraq",
  },
  {
    flag_code: "ie",
    code: "+353",
    name: "Ireland",
  },
  {
    flag_code: "il",
    code: "+972",
    name: "Israel",
  },
  {
    flag_code: "it",
    code: "+39",
    name: "Italy",
  },
  {
    flag_code: "jm",
    code: "+1876",
    name: "Jamaica",
  },
  {
    flag_code: "jp",
    code: "+81",
    name: "Japan",
  },
  {
    flag_code: "jo",
    code: "+962",
    name: "Jordan",
  },
  {
    flag_code: "kz",
    code: "+7",
    name: "Kazakhstan",
  },
  {
    flag_code: "ke",
    code: "+254",
    name: "Kenya",
  },
  {
    flag_code: "ki",
    code: "+686",
    name: "Kiribati",
  },
  {
    flag_code: "xk",
    code: "+383",
    name: "Kosovo",
  },
  {
    flag_code: "kw",
    code: "+965",
    name: "Kuwait",
  },
  {
    flag_code: "kg",
    code: "+996",
    name: "Kyrgyzstan",
  },
  {
    flag_code: "la",
    code: "+856",
    name: "Laos",
  },
  {
    flag_code: "lv",
    code: "+371",
    name: "Latvia",
  },
  {
    flag_code: "lb",
    code: "+961",
    name: "Lebanon",
  },
  {
    flag_code: "ls",
    code: "+266",
    name: "Lesotho",
  },
  {
    flag_code: "lr",
    code: "+231",
    name: "Liberia",
  },
  {
    flag_code: "ly",
    code: "+218",
    name: "Libya",
  },
  {
    flag_code: "li",
    code: "+423",
    name: "Liechtenstein",
  },
  {
    flag_code: "lt",
    code: "+370",
    name: "Lithuania",
  },
  {
    flag_code: "lu",
    code: "+352",
    name: "Luxembourg",
  },
  {
    flag_code: "mo",
    code: "+853",
    name: "Macau",
  },
  {
    flag_code: "mk",
    code: "+389",
    name: "Macedonia",
  },
  {
    flag_code: "mg",
    code: "+261",
    name: "Madagascar",
  },
  {
    flag_code: "mw",
    code: "+265",
    name: "Malawi",
  },
  {
    flag_code: "my",
    code: "+60",
    name: "Malaysia",
  },
  {
    flag_code: "mv",
    code: "+960",
    name: "Maldives",
  },
  {
    flag_code: "ml",
    code: "+223",
    name: "Mali",
  },
  {
    flag_code: "mt",
    code: "+356",
    name: "Malta",
  },
  {
    flag_code: "mh",
    code: "+692",
    name: "Marshall Islands",
  },
  {
    flag_code: "mq",
    code: "+596",
    name: "Martinique",
  },
  {
    flag_code: "mr",
    code: "+222",
    name: "Mauritania",
  },
  {
    flag_code: "mu",
    code: "+230",
    name: "Mauritius",
  },
  {
    flag_code: "mx",
    code: "+52",
    name: "Mexico",
  },
  {
    flag_code: "fm",
    code: "+691",
    name: "Micronesia",
  },
  {
    flag_code: "md",
    code: "+373",
    name: "Moldova",
  },
  {
    flag_code: "mc",
    code: "+377",
    name: "Monaco",
  },
  {
    flag_code: "mn",
    code: "+976",
    name: "Mongolia",
  },
  {
    flag_code: "me",
    code: "+382",
    name: "Montenegro",
  },
  {
    flag_code: "ma",
    code: "+212",
    name: "Morocco",
  },
  {
    flag_code: "mz",
    code: "+258",
    name: "Mozambique",
  },
  {
    flag_code: "mm",
    code: "+95",
    name: "Myanmar",
  },
  {
    flag_code: "na",

    code: "+264",
    name: "Namibia",
  },
  {
    flag_code: "nr",
    code: "+674",
    name: "Nauru",
  },
  {
    flag_code: "np",
    code: "+977",
    name: "Nepal",
  },
  {
    flag_code: "nl",
    code: "+31",
    name: "Netherlands",
  },
  {
    flag_code: "nc",
    code: "+687",
    name: "New Caledonia",
  },
  {
    flag_code: "nz",
    code: "+64",
    name: "New Zealand",
  },
  {
    flag_code: "ni",
    code: "+505",
    name: "Nicaragua",
  },
  {
    flag_code: "ne",
    code: "+227",
    name: "Niger",
  },
  {
    flag_code: "ng",
    code: "+234",
    name: "Nigeria",
  },
  {
    flag_code: "kp",
    code: "+850",
    name: "North Korea",
  },
  {
    flag_code: "no",
    code: "+47",
    name: "Norway",
  },
  {
    flag_code: "om",
    code: "+968",
    name: "Oman",
  },
  {
    flag_code: "pk",
    code: "+92",
    name: "Pakistan",
  },
  {
    flag_code: "pw",
    code: "+680",
    name: "Palau",
  },
  {
    flag_code: "ps",
    code: "+970",
    name: "Palestine",
  },
  {
    flag_code: "pa",
    code: "+507",
    name: "Panama",
  },
  {
    flag_code: "pg",
    code: "+675",
    name: "Papua New Guinea",
  },
  {
    flag_code: "py",
    code: "+595",
    name: "Paraguay",
  },
  {
    flag_code: "pe",
    code: "+51",
    name: "Peru",
  },
  {
    flag_code: "ph",
    code: "+63",
    name: "Philippines",
  },
  {
    flag_code: "pl",
    code: "+48",
    name: "Poland",
  },
  {
    flag_code: "pt",
    code: "+351",
    name: "Portugal",
  },
  {
    flag_code: "pr",
    code: "+1",
    name: "Puerto Rico",
  },
  {
    flag_code: "qa",
    code: "+974",
    name: "Qatar",
  },
  {
    flag_code: "re",
    code: "+262",
    name: "Réunion",
  },
  {
    flag_code: "ro",
    code: "+40",
    name: "Romania",
  },
  {
    flag_code: "ru",
    code: "+7",
    name: "Russia",
  },
  {
    flag_code: "rw",
    code: "+250",
    name: "Rwanda",
  },
  {
    flag_code: "kn",
    code: "+1869",
    name: "Saint Kitts and Nevis",
  },
  {
    flag_code: "lc",
    code: "+1758",
    name: "Saint Lucia",
  },
  {
    flag_code: "vc",
    code: "+1784",
    name: "Saint Vincent and the Grenadines",
  },
  {
    flag_code: "ws",
    code: "+685",
    name: "Samoa",
  },
  {
    flag_code: "sm",
    code: "+378",
    name: "San Marino",
  },
  {
    flag_code: "st",
    code: "+239",
    name: "São Tomé and Príncipe",
  },
  {
    flag_code: "sa",
    code: "+966",
    name: "Saudi Arabia",
  },
  {
    flag_code: "sn",
    code: "+221",
    name: "Senegal",
  },
  {
    flag_code: "rs",
    code: "+381",
    name: "Serbia",
  },
  {
    flag_code: "sc",
    code: "+248",
    name: "Seychelles",
  },
  {
    flag_code: "sl",
    code: "+232",
    name: "Sierra Leone",
  },
  {
    flag_code: "sg",
    code: "+65",
    name: "Singapore",
  },
  {
    flag_code: "sk",
    code: "+421",
    name: "Slovakia",
  },
  {
    flag_code: "si",
    code: "+386",
    name: "Slovenia",
  },
  {
    flag_code: "sb",
    code: "+677",
    name: "Solomon Islands",
  },
  {
    flag_code: "so",
    code: "+252",
    name: "Somalia",
  },
  {
    flag_code: "za",
    code: "+27",
    name: "South Africa",
  },
  {
    flag_code: "kr",
    code: "+82",
    name: "South Korea",
  },
  {
    flag_code: "ss",
    code: "+211",
    name: "South Sudan",
  },
  {
    flag_code: "es",
    code: "+34",
    name: "Spain",
  },
  {
    flag_code: "lk",
    code: "+94",
    name: "Sri Lanka",
  },
  {
    flag_code: "sd",
    code: "+249",
    name: "Sudan",
  },
  {
    flag_code: "sr",
    code: "+597",
    name: "Suriname",
  },
  {
    flag_code: "sz",
    code: "+268",
    name: "Swaziland",
  },
  {
    flag_code: "se",
    code: "+46",
    name: "Sweden",
  },
  {
    flag_code: "ch",
    code: "+41",
    name: "Switzerland",
  },
  {
    flag_code: "sy",
    code: "+963",
    name: "Syria",
  },
  {
    flag_code: "tw",
    code: "+886",
    name: "Taiwan",
  },
  {
    flag_code: "tj",
    code: "+992",
    name: "Tajikistan",
  },
  {
    flag_code: "tz",
    code: "+255",
    name: "Tanzania",
  },
  {
    flag_code: "th",
    code: "+66",
    name: "Thailand",
  },
  {
    flag_code: "tl",
    code: "+670",
    name: "Timor-Leste",
  },
  {
    flag_code: "tg",
    code: "+228",
    name: "Togo",
  },
  {
    flag_code: "to",
    code: "+676",
    name: "Tonga",
  },
  {
    flag_code: "tt",
    code: "+1868",
    name: "Trinidad and Tobago",
  },
  {
    flag_code: "tn",
    code: "+216",
    name: "Tunisia",
  },
  {
    flag_code: "tr",
    code: "+90",
    name: "Turkey",
  },
  {
    flag_code: "tm",
    code: "+993",
    name: "Turkmenistan",
  },
  {
    flag_code: "tv",
    code: "+688",
    name: "Tuvalu",
  },
  {
    flag_code: "ug",
    code: "+256",
    name: "Uganda",
  },
  {
    flag_code: "ua",
    code: "+380",
    name: "Ukraine",
  },
  {
    flag_code: "ae",
    code: "+971",
    name: "United Arab Emirates",
  },
  {
    flag_code: "gb",
    code: "+44",
    name: "United Kingdom",
  },
  {
    flag_code: "uy",
    code: "+598",
    name: "Uruguay",
  },
  {
    flag_code: "uz",
    code: "+998",
    name: "Uzbekistan",
  },
  {
    flag_code: "vu",
    code: "+678",
    name: "Vanuatu",
  },
  {
    flag_code: "va",
    code: "+39",
    name: "Vatican City",
  },
  {
    flag_code: "ve",
    code: "+58",
    name: "Venezuela",
  },
  {
    flag_code: "vn",
    code: "+84",
    name: "Vietnam",
  },
  {
    flag_code: "ye",
    code: "+967",
    name: "Yemen",
  },
  {
    flag_code: "zm",
    code: "+260",
    name: "Zambia",
  },
  {
    flag_code: "zw",
    code: "+263",
    name: "Zimbabwe",
  },
];
