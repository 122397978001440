import React, { forwardRef, useRef, useState } from "react";

export const Footer = forwardRef(({ btn_name, btn_click, desc }, ref) => {
  const [showMore, setshowMore] = useState(false);
  const contentRef = useRef(null);
  return (
    <div className="footer" ref={ref} id="footer">
      <div
        className="footer_btn"
        onClick={() => {
          btn_click();
        }}
      >
        {btn_name}
      </div>
      {desc ? (
        <div className="footer_desc">
          <span>
            Disclaimer: Arva Health's Egg Freezing Calculator is for
            informational purposes only and
          </span>
          {showMore ? (
            <span>
              {" "}
              should not replace medical advice, diagnoses, or treatment.
              Consult a fertility specialist for any medical concerns. The
              results are estimates and may not reflect actual outcomes.
            </span>
          ) : (
            <span>...</span>
          )}
          <span
            className="readmore_btn"
            onClick={() => {
              setshowMore((prev) => !prev);
            }}
          >
            {!showMore ? "show more" : "show less"}
          </span>
        </div>
      ) : (
        ""
      )}
    </div>
  );
});
