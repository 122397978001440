const default_appdata = {};

export const checktoken = {
  ...default_appdata,
};

const AppData = (state = checktoken, { type, payload }) => {
  //
  switch (type) {
    case "setCheckToken":
      return { ...state, ...payload };
    default:
      return state;
  }
};
export default AppData;
