import React from "react";

export const CheckBox = ({ checked, onClick }) => {
  return (
    <div className={`checkbox ${checked ? "checked" : ""}`} onClick={onClick}>
      {checked && (
        <>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            xlink="http://www.w3.org/1999/xlink"
            width="12"
            height="12"
            x="0"
            y="0"
            viewBox="0 0 24 24"
            style={{ enableBackground: "new 0 0 512 512" }}
          >
            <g>
              <path
                d="M18.7 7.2c-.4-.4-1-.4-1.4 0l-7.5 7.5-3.1-3.1c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l3.8 3.8c.2.2.4.3.7.3s.5-.1.7-.3l8.2-8.2c.4-.4.4-1 0-1.4z"
                fill="#000000"
                opacity="1"
                data-original="#000000"
                class=""
              ></path>
            </g>
          </svg>
        </>
      )}
    </div>
  );
};
