function calculateEggFreezingResults(
    age,
    heightFt,
    heightIn,
    weight,
    ethnicity,
    amhLevel,
    amhValue,
    pcos,
    endometriosis
  ) {
    const heightCm = heightFt * 30.48 + heightIn * 2.54;
    const bmi = calculateBMI(heightCm, weight);
    const oocytesPerCycle = calculateOocytes(
      age,
      amhLevel,
      amhValue,
      pcos,
      endometriosis
    );
    const clbr = calculateCLBR(age, bmi, ethnicity, pcos, endometriosis);
  
    const results = [];
    for (let cycles = 1; cycles <= 3; cycles++) {
      const totalOocytes = Math.round(oocytesPerCycle * cycles);
      const liveBirthChance1 = calculateLiveBirthChance(
        totalOocytes,
        clbr,
        age,
        1
      );
      const liveBirthChance2 = calculateLiveBirthChance(
        totalOocytes,
        clbr,
        age,
        2
      );
  
      results.push({
        cycles: cycles,
        total_oocytes: totalOocytes,
        live_birth_chance_1: Math.round(liveBirthChance1 * 100),
        live_birth_chance_2: Math.round(liveBirthChance2 * 100),
      });
    }
  
    return results;
  }
  
  function calculateBMI(height, weight) {
    return weight / (height / 100) ** 2;
  }
  
  function calculateOocytes(age, amhLevel, amhValue, pcos, endometriosis) {
    let baseOocytes = 18 * (1 / (1 + Math.exp(0.15 * (age - 37))));
  
    if (amhLevel && amhValue) {
      const amhFactor = Math.min(Math.max(amhValue / 3, 0.7), 1.3);
      baseOocytes *= amhFactor;
    }
  
    if (pcos) {
      baseOocytes *= 1.2;
    }
  
    if (endometriosis) {
      baseOocytes *= 0.9;
    }
  
    return baseOocytes;
  }
  
  function calculateCLBR(age, bmi, ethnicity, pcos, endometriosis) {
    let baseCLBR = 0.7 * (1 / (1 + Math.exp(0.2 * (age - 35))));
  
    const bmiFactor = -0.0002 * (bmi - 25) ** 2 + 1;
    baseCLBR *= bmiFactor;
  
    if (ethnicity !== "caucasian") {
      baseCLBR *= 0.95;
    }
  
    if (endometriosis) {
      baseCLBR *= 0.9;
    }
  
    return baseCLBR;
  }
  
  function calculateLiveBirthChance(oocytes, clbr, age, numBirths) {
    const ageFactor = Math.max(0, 1 - (age - 30) * 0.02);
    const baseLBR = 1 - Math.pow(1 - clbr, oocytes / 15);
    let lbr = baseLBR * ageFactor;
  
    if (numBirths === 1) {
      lbr = Math.min(0.95, lbr + oocytes * 0.01);
    } else {
      lbr = Math.pow(lbr, 1.5) * 0.8;
    }
  
    return Math.min(0.99, Math.max(0, lbr));
  }
  
  function estimateFutureAMH(currentAge, currentAMH, yearsToAdd) {
    let futureAMH = currentAMH;
    for (let i = 0; i < yearsToAdd; i++) {
      const age = currentAge + i;
      if (age < 35) {
        futureAMH -= 0.2;
      } else if (age >= 35 && age < 40) {
        futureAMH -= 0.1;
      } else {
        futureAMH -= 0.1;
      }
      futureAMH = Math.max(futureAMH, 0);
    }
    return futureAMH;
  }
  
  function calculateFutureResults(
    currentAge,
    yearsToAdd,
    currentAMH,
    heightFt,
    heightIn,
    weight,
    ethnicity,
    amhKnown,
    pcos,
    endometriosis
  ) {
    const futureAge = currentAge + parseInt(yearsToAdd);
    const futureAMH = estimateFutureAMH(
      currentAge,
      currentAMH,
      parseInt(yearsToAdd)
    );
  
    let futureAMHLevel;
    if (futureAMH < 1) {
      futureAMHLevel = "low";
    } else if (futureAMH >= 1 && futureAMH <= 3.5) {
      futureAMHLevel = "normal";
    } else {
      futureAMHLevel = "high";
    }
  
    return calculateEggFreezingResults(
      futureAge,
      heightFt,
      heightIn,
      weight,
      ethnicity,
      futureAMHLevel, // Use futureAMHLevel instead of amhKnown
      futureAMH,
      pcos,
      endometriosis
    );
  }
  
  export { calculateEggFreezingResults, calculateFutureResults };