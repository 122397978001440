import React from "react";
import { Navbar } from "../component/navbar/Navbar";
import { useNavigate, useParams } from "react-router-dom";
import { Footer } from "../component/footer/Footer";

export const Cover = () => {
  const { type = "" } = useParams();
  const nav = useNavigate();
  return (
    <div className="cover_main">
      <Navbar
        {...{
          nobackbtn: !type,
          goback: () => {
            nav(-1);
          },
        }}
      />
      <div className="cover_section">
        <div>
          <div className="cover_section_chip">Egg Freezing Calculator</div>
          <div className="cover_section_title">What are my chances?</div>
          <div className="cover_section_desc">
            This calculator predicts the number of eggs that will be retrieved
            and the likelihood of achieving a live birth, so you get a sense of
            how many cycles you will need to do according to your goals.
          </div>
        </div>
      </div>
      <Footer
        {...{
          btn_name: "Calculate",
          desc: "",
          btn_click: () => {
            nav("form");
          },
        }}
      />
    </div>
  );
};
