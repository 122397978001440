import React, { useEffect, useRef, useState } from "react";
import { Navbar } from "../component/navbar/Navbar";
import { useNavigate, useParams } from "react-router-dom";
// import { Footer } from "../component/footer/Footer";
import CustInputMuiClone from "../component/custinp/CustInputMuiClone";
import { CheckBox } from "../component/checkbox/CheckBox";
import CustPhoneCountries from "../component/custinp/CustPhoneCountries";
import { calculateEggFreezingResults } from "../utils/egg_freezing_calc";
import axios from "axios";
import { Footer } from "../component/footer/Footer";

export const Form = ({
  resultsvalues,
  setresultsvalues,
  pagevalues,
  setpagevalues,
  dignosedList,
  setDignosedList,
}) => {
  const { type = "" } = useParams();
  const nav = useNavigate();
  const formFooter = useRef(null);
  const [errpagevalues, seterrpagevalues] = useState({
    age: "",
    height: {
      ft: "",
      in: "",
    },
    weight: "",
    ethnicity: "",
    amh_ans: "",
    amhvalue: "",
    dignosed: "",
    name: "",
    phone: "",
    phone_code: {
      flag_code: "in",
      code: "+91",
      name: "India",
    },
  });
  const [dropDown, setdropDown] = useState(false);

  const [hoveredIndex, setHoveredIndex] = useState(null);

  const toggleCheckbox = (index) => {
    const updatedData = [...dignosedList];
    updatedData[index].checkbox = !updatedData[index].checkbox;
    setDignosedList(updatedData);
    setpagevalues((prev) => ({
      ...prev,
      dignosed: [
        ...updatedData.filter((el) => el.checkbox).map((el) => el.name),
      ],
    }));
    seterrpagevalues((prev) => ({
      ...prev,
      dignosed: false,
    }));
  };

  const setphone_code = (obj) => {
    setpagevalues((prev) => ({
      ...prev,
      phone_code: {
        ...obj,
      },
    }));
  };
  const [height, setHeight] = useState(160);
  const updateHeight = () => {
    if (formFooter.current) {
      // setHeight(formFooter.current.clientHeight);
      const footerHeight = formFooter.current.clientHeight;
      setHeight(footerHeight <= 160 ? 160 : footerHeight);
    }
  };

  window.onclick = () => {
    setdropDown(false);
  };
  useEffect(() => {
    updateHeight();
  }, []);

  useEffect(() => {
    updateHeight();
    window.addEventListener("resize", updateHeight);
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  return (
    <div className="form_page_main">
      <Navbar
        {...{
          nobackbtn: !type,
          goback: () => {
            nav(-1);
          },
        }}
      />
      <div
        className="form_page_section"
        id="myDiv"
        style={{ "--footer_height": `${height}px` }}
      >
        <div className="form_page_section_inner">
          <div className="form_inputs">
            <div className="form_inputs_title">Age</div>
            <CustInputMuiClone
              {...{
                unique_id: "age",
                attr: {
                  readonly: false,
                  error: errpagevalues.age,
                  error_msg:
                    // Number(pagevalues.age) < 18
                    //   ? "Age should be greater than 17"
                    //   :
                    "",
                  value: pagevalues.age,
                  inputMode: "text",
                  event: {
                    change: ({ target }) => {
                      let value = target.value;
                      if (Number(value.slice(-1)).toString() == "NaN") {
                        return;
                      }
                      const cleanedInput = value.replace(/\D/g, "");
                      setpagevalues((prev) => ({
                        ...prev,
                        age: cleanedInput,
                      }));
                      seterrpagevalues((prev) => ({
                        ...prev,
                        age: !cleanedInput,
                      }));
                    },
                    onkeydown: (e) => {
                      if (e.key == "Enter") {
                      }
                    },
                  },
                },
                data: {
                  prefix: "",
                  suffix: "yrs",
                },
              }}
            />
          </div>
          <div className="form_inputs">
            <div className="form_inputs_title">Height</div>
            <div className="form_inputs_box">
              <CustInputMuiClone
                {...{
                  unique_id: "heightft",
                  attr: {
                    readonly: false,
                    error: errpagevalues.height.ft,
                    error_msg: "",
                    value: pagevalues?.height?.ft,
                    inputMode: "text",
                    event: {
                      change: ({ target }) => {
                        let value = target.value;
                        if (Number(value.slice(-1)).toString() == "NaN") {
                          return;
                        }
                        const cleanedInput = value.replace(/\D/g, "");
                        setpagevalues((prev) => ({
                          ...prev,
                          height: {
                            ...prev.height,
                            ft: cleanedInput,
                          },
                        }));
                        seterrpagevalues((prev) => ({
                          ...prev,
                          height: {
                            ...prev.height,
                            ft: !cleanedInput,
                          },
                        }));
                      },
                      onkeydown: (e) => {
                        if (e.key == "Enter") {
                        }
                      },
                    },
                  },
                  data: {
                    prefix: "",
                    suffix: "ft",
                  },
                }}
              />
              <CustInputMuiClone
                {...{
                  unique_id: "heightin",
                  attr: {
                    readonly: false,
                    error: errpagevalues.height?.in,
                    error_msg: "",
                    value: pagevalues?.height?.in,
                    inputMode: "text",
                    event: {
                      change: ({ target }) => {
                        let value = target.value;
                        if (Number(value.slice(-1)).toString() == "NaN") {
                          return;
                        }
                        const cleanedInput = value.replace(/\D/g, "");
                        // if (Number(cleanedInput) > 11) {
                        //   return;
                        // }
                        const finalValue =
                          Number(cleanedInput) > 11 ? "11" : cleanedInput;
                        setpagevalues((prev) => ({
                          ...prev,
                          height: {
                            ...prev.height,
                            in: finalValue,
                          },
                        }));
                        // seterrpagevalues((prev) => ({
                        //   ...prev,
                        //   height: {
                        //     ...prev.height,
                        //     in: !cleanedInput,
                        //   },
                        // }));
                      },
                      onkeydown: (e) => {
                        if (e.key == "Enter") {
                        }
                      },
                    },
                  },
                  data: {
                    prefix: "",
                    suffix: "in",
                  },
                }}
              />
            </div>
          </div>
          <div className="form_inputs">
            <div className="form_inputs_title">Weight</div>
            <CustInputMuiClone
              {...{
                unique_id: "weight",
                attr: {
                  readonly: false,
                  error: errpagevalues.weight,
                  error_msg: "",
                  value: pagevalues.weight,
                  inputMode: "text",
                  event: {
                    change: ({ target }) => {
                      let value = target.value;

                      // Allow only numbers and one decimal point
                      const cleanedInput = value.replace(/[^0-9.]/g, "");

                      // Prevent multiple decimal points
                      if ((cleanedInput.match(/\./g) || []).length > 1) {
                        return;
                      }

                      // Check if the last character is a valid number or decimal point
                      if (
                        isNaN(Number(value.slice(-1))) &&
                        value.slice(-1) !== "."
                      ) {
                        return;
                      }
                      setpagevalues((prev) => ({
                        ...prev,
                        weight: cleanedInput,
                      }));
                      seterrpagevalues((prev) => ({
                        ...prev,
                        weight: !cleanedInput,
                      }));
                    },
                    onkeydown: (e) => {
                      if (e.key == "Enter") {
                      }
                    },
                  },
                },
                data: {
                  prefix: "",
                  suffix: "kgs",
                },
              }}
            />
          </div>
          <div className="form_inputs">
            <div className="form_inputs_title">Ethnicity</div>

            <div
              className={
                "form_select" + (errpagevalues.ethnicity ? " error" : "")
              }
              // error={errpagevalues.ethnicity}
              onClick={(e) => {
                e.stopPropagation();
                setdropDown((prev) => !prev);
              }}
            >
              <div>{pagevalues.ethnicity || <span>Select</span>}</div>
              <div>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={
                    dropDown
                      ? {
                          transform: "rotate(180deg)",
                        }
                      : {}
                  }
                >
                  <path
                    d="M4 6L8 10L12 6"
                    stroke="#AAAAAA"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
            {dropDown ? (
              <div className="form_select_options">
                {["Asian", "Caucasian", "African", "Hispanic", "Other"].map(
                  (el, i) => (
                    <div
                      key={i}
                      active={(pagevalues.ethnicity == el).toString()}
                      onClick={() => {
                        setpagevalues((prev) => ({
                          ...prev,
                          ethnicity: el,
                        }));
                        seterrpagevalues((prev) => ({
                          ...prev,
                          ethnicity: !el,
                        }));
                        setdropDown(false);
                      }}
                    >
                      {el}
                    </div>
                  )
                )}
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="form_question">
            <div className="form_inputs_title">
              Do you know your AMH levels?
            </div>
            <div className="form_inputs_desc">
              AMH test measures the level of anti-Müllerian hormone (AMH) in the
              blood. It requires only a standard blood sample, and necessary to
              provide an accurate and personal prediction.
            </div>
            <div className="form_box">
              {["Yes", "No"].map((el, i) => (
                <div
                  className={
                    "form_btn" + (pagevalues.amh_ans == el ? " active_btn" : "")
                  }
                  key={i}
                  onClick={() => {
                    setpagevalues((prev) => ({
                      ...prev,
                      amh_ans: el,
                    }));
                    seterrpagevalues((prev) => ({
                      ...prev,
                      amh_ans: !el,
                    }));
                  }}
                >
                  {el}
                </div>
              ))}
            </div>
            <div
              className={
                "form_error" + (errpagevalues.amh_ans ? " active" : "")
              }
            >
              Select any one
            </div>
          </div>

          {
            {
              Yes: (
                <div className="form_inputs selection_y_n">
                  <div className="form_inputs_title">AMH Value</div>
                  <CustInputMuiClone
                    {...{
                      unique_id: "amh",
                      attr: {
                        readonly: false,
                        error: errpagevalues.amhvalue,
                        error_msg: "",
                        value: pagevalues.amhvalue,
                        inputMode: "text",
                        event: {
                          change: ({ target }) => {
                            let value = target.value;

                            // Allow only numbers and one decimal point
                            const cleanedInput = value.replace(/[^0-9.]/g, "");

                            // Prevent multiple decimal points
                            if ((cleanedInput.match(/\./g) || []).length > 1) {
                              return;
                            }

                            // Check if the last character is a valid number or decimal point
                            if (
                              isNaN(Number(value.slice(-1))) &&
                              value.slice(-1) !== "."
                            ) {
                              return;
                            }
                            setpagevalues((prev) => ({
                              ...prev,
                              amhvalue: cleanedInput,
                            }));
                            seterrpagevalues((prev) => ({
                              ...prev,
                              amhvalue: !cleanedInput,
                            }));
                          },
                          onkeydown: (e) => {
                            if (e.key == "Enter") {
                            }
                          },
                        },
                      },
                      data: {
                        prefix: "",
                        suffix: "ng/ml",
                      },
                    }}
                  />
                </div>
              ),
              No: (
                <div
                  className="form_test_box"
                  onClick={() => {
                    window.open(
                      "http://shop.arva.health/cart?ProductId=FT02",
                      "_blank"
                    );
                  }}
                >
                  <div className="form_test_box_img">
                    <img src="https://cdn.sanity.io/images/nao51hyy/production/39c129fc70340898e9d4ffce74d0be5f2598b821-200x200.png" />
                  </div>
                  <div className="form_test_box_detail">
                    <div className="form_test_box_detail_title">
                      Starter Fertility Test
                    </div>
                    <div className="form_test_box_detail_status">
                      Highly recommended
                    </div>
                  </div>
                  <div className="form_test_box_svg">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.5 4.16667L13.3333 10L7.5 15.8333"
                        stroke="#5F1D1B"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
              ),
            }[pagevalues.amh_ans]
          }
          <div className="form_inputs form_check_boxs">
            <div className="form_inputs_title">
              Have you been diagnosed with?
            </div>
            <div className="form_checkbox_inputs">
              {dignosedList?.map((el, index) => (
                <div
                  className="checkbox_input"
                  onClick={() => toggleCheckbox(index)}
                >
                  <CheckBox checked={el.checkbox} />
                  <div>{el.name}</div>
                  <div
                    className="checkbox_input_info"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    onMouseEnter={() => {
                      setHoveredIndex(index);
                    }}
                    onMouseLeave={() => {
                      setHoveredIndex(null);
                    }}
                  >
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
                        fill="var(--gray-g-300)"
                      />
                      <path
                        d="M10 6.66667C10.7671 6.66667 11.3889 6.04484 11.3889 5.27778C11.3889 4.51072 10.7671 3.88889 10 3.88889C9.23294 3.88889 8.61111 4.51072 8.61111 5.27778C8.61111 6.04484 9.23294 6.66667 10 6.66667Z"
                        fill="white"
                      />
                      <path
                        d="M8.88891 8.33333H11.1111V16.1111H8.88891V8.33333Z"
                        fill="white"
                      />
                    </svg>
                  </div>

                  {hoveredIndex === index && (
                    <div className="tooltip">{el.i_desc}</div>
                  )}
                </div>
              ))}
            </div>
            {/* <div
              className={
                "form_error" + (errpagevalues.dignosed ? " active" : "")
              }
            >
              Select at least one
            </div> */}
          </div>
          <div className="form_inputs">
            <div className="form_inputs_title">Name</div>
            <CustInputMuiClone
              {...{
                unique_id: "name",
                attr: {
                  readonly: false,
                  error: errpagevalues.name,
                  error_msg: "",
                  value: pagevalues.name,
                  inputMode: "text",
                  event: {
                    change: ({ target }) => {
                      setpagevalues((prev) => ({
                        ...prev,
                        name: target.value.replace(/[^a-zA-Z]/g, ""),
                      }));
                      seterrpagevalues((prev) => ({
                        ...prev,
                        name: !target.value.replace(/[^a-zA-Z]/g, ""),
                      }));
                    },
                    onkeydown: (e) => {
                      if (e.key == "Enter") {
                      }
                    },
                  },
                },
                data: {
                  prefix: "",
                  suffix: "",
                },
              }}
            />
          </div>
          <div className="form_inputs">
            <div className="form_inputs_title">Phone</div>
            <CustInputMuiClone
              {...{
                unique_id: "phone",
                attr: {
                  class: "cust_phone_input_main",
                  readonly: false,
                  error: errpagevalues.phone,
                  error_msg: "",
                  value: pagevalues.phone,
                  inputMode: "text",
                  event: {
                    change: ({ target }) => {
                      let value = target.value;
                      if (Number(value.slice(-1)).toString() == "NaN") {
                        return;
                      }
                      const cleanedInput = value.replace(/\D/g, "");
                      let formattedInput = "";
                      if (cleanedInput.length > 5) {
                        formattedInput =
                          cleanedInput.slice(0, 5) +
                          " " +
                          cleanedInput.slice(5);
                      } else {
                        formattedInput = cleanedInput;
                      }
                      if (cleanedInput.length === 11) {
                        return;
                      }
                      setpagevalues((prev) => ({
                        ...prev,
                        phone: formattedInput.slice(0, 11),
                      }));
                      seterrpagevalues((prev) => ({
                        ...prev,
                        phone:
                          formattedInput.length == 11 ? "" : "Error message",
                      }));
                    },
                    onkeydown: (e) => {
                      if (e.key == "Enter") {
                      }
                    },
                  },
                },
                data: {
                  prefix: (
                    <CustPhoneCountries
                      value={pagevalues.phone_code}
                      setvalue={setphone_code}
                    />
                  ),
                  title: "",
                  suffix: "",
                },
              }}
            />
          </div>
        </div>
      </div>

      <Footer
        btn_name="Calculate results"
        desc={true}
        btn_click={() => {
          if (
            !pagevalues.age.trim() ||
            !(Number(pagevalues.age) >= 18) ||
            !pagevalues.weight.trim() ||
            !pagevalues.height.ft.trim() ||
            // !pagevalues.height.in.trim() ||
            !pagevalues.amh_ans ||
            (pagevalues.amh_ans === "Yes" && !pagevalues.amhvalue.trim()) ||
            // pagevalues.dignosed.length == 0 ||
            !pagevalues.ethnicity.trim() ||
            !pagevalues.name.trim() ||
            pagevalues.phone.trim().length == 0 ||
            !/^(?:\d{5}\s\d{5})$/.test(pagevalues.phone.trim())
          ) {
            //if there is an error in age height wight  then scroll window to top
            if (
              !pagevalues.age.trim() ||
              !(Number(pagevalues.age) >= 18) ||
              !pagevalues.weight.trim() ||
              !pagevalues.height.ft.trim()
            ) {
              document.getElementById("myDiv").scrollTo({
                top: 0,
                behavior: "smooth", // Smooth scrolling to the top
              });
            }
            seterrpagevalues((prev) => ({
              ...prev,
              age: !pagevalues.age.trim() || !(Number(pagevalues.age) >= 18),
              weight: !pagevalues.weight.trim(),
              amh_ans: !pagevalues.amh_ans.trim(),
              amhvalue:
                pagevalues.amh_ans === "Yes" && !pagevalues.amhvalue.trim(),
              ethnicity: !pagevalues.ethnicity.trim(),
              // dignosed: pagevalues.dignosed.length == 0,
              height: {
                ft: !pagevalues.height.ft.trim(),
                // in: !pagevalues.height.in.trim(),
              },
              name: !pagevalues.name.trim(),
              phone:
                pagevalues.phone.trim().length == 0 ||
                !/^(?:\d{5}\s\d{5})$/.test(pagevalues.phone),
            }));
            return;
          }
          let data = {
            age: pagevalues.age,
            heightft: pagevalues.height.ft,
            heightin: pagevalues.height.in || 0,
            weight: pagevalues.weight,
            ethinicity: pagevalues.ethnicity,
            amhL: pagevalues.amh_ans == "Yes",
            amhV: pagevalues.amhvalue,
            pcos: pagevalues.dignosed.includes("PCOS"),
            endo: pagevalues.dignosed.includes("Endometriosis"),
            phone:
              pagevalues?.phone_code?.code?.replace("+", "") +
              pagevalues?.phone?.replace(" ", ""),
            name: pagevalues.name,
          };

          let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: "https://api.arva.health/tools/efc",
            headers: {
              "Content-Type": "application/json",
            },
            data: JSON.stringify(data),
            redirect: "follow",
          };
          axios
            .request(config)
            .then((response) => {
              // console.log(JSON.stringify(response.data));
            })
            .catch((error) => {
              console.log(error);
            });
          nav(`/egg-freezing-calculator/results`);
        }}
        ref={formFooter}
      />
    </div>
  );
};
