import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const ErrorPage = () => {
  const nav = useNavigate();
  useEffect(() => {
    window.location.replace("https://app.arva.health");
  }, []);

  return <div>404</div>;
};
