import React from "react";
import { ReactComponent as Logo } from "../../img/ARVA.svg";

export const Navbar = ({ goback, nobackbtn }) => {
  return (
    <div className="common_v3_nav_container">
      <div className="common_v3_nav_inner">
        <div
          className={"common_v3_nav_go_back" + (nobackbtn ? " no_show" : "")}
          onClick={() => {
            goback();
          }}
        >
          <svg
            width="9"
            height="16"
            viewBox="0 0 9 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.16246 13.5876C8.33858 13.7637 8.43752 14.0026 8.43752 14.2516C8.43752 14.5007 8.33858 14.7396 8.16246 14.9157C7.98634 15.0918 7.74747 15.1908 7.4984 15.1908C7.24932 15.1908 7.01045 15.0918 6.83433 14.9157L0.584333 8.66569C0.496933 8.57859 0.427586 8.4751 0.380268 8.36114C0.332951 8.24719 0.308594 8.12501 0.308594 8.00163C0.308594 7.87824 0.332951 7.75606 0.380268 7.64211C0.427586 7.52815 0.496933 7.42466 0.584333 7.33756L6.83433 1.08756C7.01045 0.911443 7.24932 0.8125 7.4984 0.8125C7.74747 0.8125 7.98634 0.911443 8.16246 1.08756C8.33858 1.26368 8.43752 1.50255 8.43752 1.75163C8.43752 2.0007 8.33858 2.23957 8.16246 2.41569L2.5773 8.00084L8.16246 13.5876Z"
              fill="#5F1D1B"
            />
          </svg>
        </div>
        <div className="common_v3_nav_logo">
          <Logo onClick={() => window.location.replace("http://arva.health")} />
        </div>
        <div className="no_show">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M8.33333 15.8334L2.5 10.0001M2.5 10.0001L8.33333 4.16675M2.5 10.0001H17.5"
              stroke="#5F1D1B"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};
